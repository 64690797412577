// Collections page 
<template>
<div class="container-fluid p-0"  v-if="catalogList.length !== 0">
  <!-- Hero -->
  <div class="row collection-hero py-5">
    <h2 class="text-green text-center montserrat-hero text-uppercase py-3">All Cookies</h2>
  </div>

  <!-- Products -->
  <div class="row collection-container mx-auto pb-5">
    
    <div v-for="item in catalogList" :key="item.id" :id="item.id" :style="{ 'display': item.type == 'ITEM' ? 'block' : 'none !important' }" class="col-12 col-lg-3 product-card d-flex flex-column justify-content-start px-1 px-md-2">

      <div v-if="item.type == 'ITEM' && item.item_data.name !== 'Shipping'">

        <router-link :to="{name: 'Product', params: { id: item.id } }" class="router-link">
          <img v-if="item.image" :src="item.image.url" class="img-fluid mt-5" />
          <h4 class="text-green montserrat-name mt-1 item-name">{{ item.item_data.name }}</h4>
        </router-link>

        <div class="d-flex justify-content-evenly">
          <div v-for="(variation, index) in item.item_data.variations" :key="variation.id">
            <!-- Radio selector buttons -->
            <!-- <p>v-model: {{ selectedVariation[variation.id] }}</p> -->
            
            <span class="d-flex flex-column align-items-center">
              <input :checked="index == 1" type="radio" :value="variation.id" v-model="selectedVariation[variation.id]" :name="`radio-${item.id}`" @change="updateRadioSelector(item, variation)" />
            
              <p class="mb-0 montserrat-medium-small text-green">{{ variation.item_variation_data.name }}</p>

              <!-- <span class="strikeout-red"> -->
              <p class="mb-0 montserrat-small text-green">${{ formatPrice(variation.item_variation_data.price_money.amount) }}</p>
              <!-- </span> -->
              <!-- <p class="mb-0 montserrat-small text-green">${{ applyDiscount(item, variation) }}</p> -->
            </span>
          </div>
        </div>

        <div> 
          <div @click="addToCart(item.selected, item, quantity = 1, item.selectedPrice, item.image.url, item.discount)" class="blaked-btn add-to-cart py-1 py-lg-2 mx-auto my-lg-3 mb-3 mt-1 w-100 d-none d-lg-block">Add to Cart | ${{ formatPrice(item.selectedPrice) }}</div>
          <div @click="addToCart(item.selected, item, quantity = 1, item.selectedPrice, item.image.url, item.discount)" class="blaked-btn add-to-cart  py-1 py-lg-2 mx-auto my-lg-3 mb-3 mt-1 w-100 d-block d-lg-none">Add to Cart</div>
        </div>

        <div class="d-flex flex-column flex-lg-row justify-content-between">
          <p class="text-green montserrat-small">Per Cookie: 100mg CBD ultra broad spectrum distillate </p>
          <!-- <img src="../assets/blaked-cbd-stars.png" class="img-fluid cbd-star"> -->
          <!-- <p class="text-green montserrat-price">${{ formatPrice(item.item_data.variations[0].item_variation_data.price_money.amount) }}</p> -->
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Shop',

  props: [
    'getCatalog',
    'catalogList',
    'addToCart'
  ],

  data() {
    return {
      catalog: this.calalogList,
      selectedVariation: [],
      discountPrice: ''
    }
  },

  methods: {
    formatPrice(num) {
      const money = num / 100
      return money.toFixed(2)
    },

    updateRadioSelector(item, variation) {
      item.selected = variation.id

      item.selectedPrice = variation.item_variation_data.price_money.amount

      // let discount = ((item.discount) / 100) * variation.item_variation_data.price_money.amount

      // item.selectedPrice = this.formatPrice(discount)

      // console.log('item.selectedprice', item.selectedPrice)
      // console.log('item.discount', item.discount)
    },

    applyDiscount(item, variation) {

      // console.log('variation in applydiscount ()', variation)
      let number = variation.item_variation_data.price_money.amount

      let percentToGet = item.discount

      let percent = (percentToGet / 100) * number

      // console.log(percentToGet + "% of " + number + " is " + percent);

      this.discountPrice = this.formatPrice(percent)

      return this.discountPrice
 
    }

  },

  computed: {
    saleAmount() {
      if (this.selectedVariation.item_variation_data.price_money.amount) {

        let num = this.selectedVariation.item_variation_data.price_money.amount * this.quantity
        let percent

        this.catalogList.forEach(item => {
          if(item.type == 'DISCOUNT' && item.discount_data.name == 'Black Friday Sale') {
            this.discount = item.discount_data.percentage

            console.log('this.discount', this.discount)

            percent = (this.discount / 100) * num
            
            return percent
          }
        })

        this.updatedPrice = percent

        console.log('this.updatedPrice', this.updatedPrice)

        return percent
        // return this.selectedVariation.item_variation_data.price_money.amount * this.quantity
      } else {
        return null
      }
    }
  }
}
</script>


<style lang="scss">
.collection-hero {
  background-image: url('../assets/collection-hero-mobile.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;


  @media(min-width: 992px) {
    background-image: url('../assets/collection-hero-desktop.png');
  }
}
.collection-container {
  max-width: 1400px;

  .product-card {

    // Sale
    .strikeout-red {
      color: #800000;
      text-decoration: line-through;
    }

    .router-link {
      text-decoration: none;
    }

    .radio-button {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid #11484f;
      background-color: rgba(211, 240, 236, 0.4);
    }


    input[type="radio"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid #11484f;
      background-color: rgba(211, 240, 236, 0.4);
      transition: 0.2s all linear;
      margin-right: 5px;
      position: relative;
      top: 4px;
    }

    input[type="radio"]:checked {
      background-color: #11484f;
    }

    .cbd-star {
      height: 15px;
      width: fit-content;

      @media(min-width: 992px) {
        height: 20px;
      }
    }

    .add-to-cart {
      box-shadow: 5px 5px 0px 0px #11484f;

      @media(min-width: 992px) {
        box-shadow: 8px 8px 0px 0px #11484f;
      }
    }

    .item-name {
      font-size: 18px;

      @media(min-width: 576px) {
        font-size: 20px;
      }

      @media(min-width: 1200px) {
        font-size: 26px;
      }
    }
  }
}
</style>
